import * as React from 'react';
import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-share.css';
import 'lightgallery/css/lg-rotate.css';
import { useNavigate } from "react-router-dom";

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgShare from 'lightgallery/plugins/share';
import lgRotate from 'lightgallery/plugins/rotate';
import { Typography } from '@mui/material';
import { Fade } from 'react-awesome-reveal';
import axios from 'axios';
import SideBar from './SideBar';
import NavigationTop from '../../Component/Navigation';

function Gallery() {
    

    const onBeforeSlide = (detail) => {
        const { index, prevIndex } = detail;
        console.log(index, prevIndex);
    };


    const [images, setImages] = React.useState([])

    const imagesItem = () => {
        axios.get('https://mealsapi.emedha.in/upload/get-approved-images').then((res) => {
            setImages(res.data)
        })
    }

    React.useEffect(() => {
        imagesItem()
    }, [])


    return (
        <div>
            <NavigationTop/>
            <Fade>
                <div className=' p-1 p-lg-5 pb-2' style={{ marginTop: 140, paddingTop: 3, borderRadius: 5, alignItems: 'center', justifyContent: 'center' }} >
                    <Typography style={{ fontSize: '1.6rem', color: 'red', fontStyle: 'italic', marginBottom: 10 }} >Gallery</Typography>
                    <LightGallery
                        onBeforeSlide={onBeforeSlide}
                        speed={790}
                        plugins={[lgThumbnail, lgZoom, lgShare, lgRotate]}

                    >
                        {
                            images.map((data) => {
                                return (
                                    <a className='galler-image' src={data.image} >
                                        <img src={data.image} className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2, border: '1px solid lightgray' }} />
                                    </a>
                                )
                            })
                        }

                    </LightGallery>

                </div>
            </Fade>
            <SideBar />
        </div>
    );
}

export default Gallery


import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Slide from '@mui/material/Slide';
import { useSelector, useDispatch } from 'react-redux';
import { OpenDonateBox } from '../UserRedux/Action/StateAction';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useState } from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@material-ui/core';
import axios from 'axios'
import Swal from 'sweetalert2';
import Grid from '@mui/system/Unstable_Grid';
import styled from '@mui/system/styled';
import { Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs'
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Donateqr from './Donateqr';
import { useNavigate } from 'react-router-dom';

const Item = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    border: '1px solid',
    borderColor: theme.palette.mode === 'dark' ? '#444d58' : '#ced7e0',
    padding: theme.spacing(1),
    borderRadius: '4px',
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DonetForm() {

    const myState = useSelector((state) => state.StateUpdate)


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [districtDrop, setDistrictDrop] = useState([])
    const [clubDrop, setClubDrop] = useState([])
    const [donationType1, setDonationType] = useState([])
    const [areas, setAreas] = useState([])
    const [isClub, setIsClub] = useState(false)
    const [Validated, setValidated] = useState(false);
    const [isMember, setIsMember] = useState(true)
    const [email, setEmail] = useState()
    const [mobile, setMobile] = useState()
    const [district, setDestrict] = useState()
    const [name, setName] = useState()
    const [area, setArea] = useState()
    const [clubName, setClub] = useState()
    const [dob, setDob] = useState()
    const [dom, setDom] = useState()
    const [donationType, setDonation] = useState()
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = useState(false)


    const handleSubmitGst = (event) => {
        setIsLoading(true)
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {

            console.log({
                name, email, mobile, district, clubName, dob, dom, donationType, area, text
            });

            axios.post('https://mealsapi.emedha.in/donate/v1/donation', {
                name, email, mobile, district, clubName, dob, dom, donationType, area, text
            }).then((res) => {
                if (res.data.status === 200) {
                    pay()
                    const thankYouUrl = `https://emedha.com/mow/thanks.php?name=${encodeURIComponent(name)}&mobile=${encodeURIComponent(mobile)}`;
                    axios.post(thankYouUrl).then((response) => {

                        console.log(response.data);
                    }).catch((error) => {

                        console.error(error);
                    });
                }
            });
        }

        setValidated(true);
    };


    const districtData = () => {
        axios.post('https://mealsapi.emedha.in/donate/v1/district').then((res) => {
            setDistrictDrop(res.data.data)
        })
    }

    const areaData = () => {
        axios.post('https://mealsapi.emedha.in/donate/v1/area').then((res) => {
            setAreas(res.data.data)
        })
    }

    const donationTypeData = () => {
        axios.post('https://mealsapi.emedha.in/donate/v1/donation-type').then((res) => {
            setDonationType(res.data.data)
        })
    }

    const clubData = (district) => {
        axios.post('https://mealsapi.emedha.in/donate/v1/club', {
            district
        }).then((res) => {
            setClubDrop(res.data.data)
            setIsClub(true)
        })
    }

    React.useEffect(() => {
        districtData()
        areaData()
        donationTypeData()
    }, [])

    const [isSelect, setIsSelect] = useState(true)
    const [isSelectText1, setIsSelectText1] = useState(false)
    const [isSelectText2, setIsSelectText2] = useState(false)
    const [isSelectText3, setIsSelectText3] = useState(false)
    const [text, setText] = useState('')
     

    const valText = () => {
        if (text === '') {
            alert('Please Select one food package')
        } else {
            setIsSelect(false)
        }
    }

    const [showc, setShowc] = useState(false)

    const [payUrl, setPayUrl] = useState()
    const [isPayConfirm, setIsPayConfirm] = useState(false)
    const [payText, setPayText] = useState()

    const pay = () => {
        const userId = sessionStorage.getItem('userId')
        axios.post(`https://mealsapi.emedha.in/pay/v1/pay`, {
            payText: payText,
            userId: userId
        }).then((res) => {
            if (res.data.success) {
                setPayUrl(res.data.url)
                window.open(res.data.url);
                setIsPayConfirm(true)
                setOpen(true)
                dispatch(OpenDonateBox());
                setIsLoading(false)
            } else {
                alert('Somthing went wrong')
            }
        }).catch(()=>{
            setOpen(false)
            alert('Payment Error')
            dispatch(OpenDonateBox());
        })
    }


    const payNow = () => {
        window.open(payUrl, '_blank');
    }

    React.useEffect(()=>{
        setPayUrl(payUrl)
    }, [payUrl, open])


    return (
        <div>

            <Dialog
                open={myState}
                onClose={() => dispatch(OpenDonateBox())}
                TransitionComponent={Transition}
                keepMounted
                style={{ height: '41rem' }}
                aria-describedby="alert-dialog-slide-description"
            >
                <CloseIcon onClick={() => {
                    dispatch(OpenDonateBox())
                    setIsClub(false)
                    setIsMember(true)
                }} style={{ cursor: 'pointer', position: 'absolute', top: 10, right: 17 }} />

                {
                    !isSelect && (
                        <DialogContent>

                            <DialogContentText id="alert-dialog-slide-description">
                                <p style={{ fontSize: 19 }} >Donate Form</p>
                                <h5>{text}</h5>
                                <br />
                                <Box className='memberbtncon' sx={{ borderRadius: '1.2rem', backgroundColor: 'rgb(225, 225, 255)', padding: '0.1rem', border: '2px solid rgb(53, 53, 111)' }} >
                                    <Button onClick={() => {
                                        setIsMember(true)
                                    }} style={{ borderRadius: '0.9rem', background: isMember ? 'rgb(53, 53, 111)' : 'white', color: isMember ? 'white' : 'rgb(53, 53, 111)', width: '50%', fontSize: '0.7rem', marginLeft: '0.1rem' }} >Member</Button>
                                    <Button onClick={() => {
                                        setIsMember(false)
                                    }} style={{ borderRadius: '0.9rem', background: !isMember ? 'rgb(53, 53, 111)' : 'white', color: !isMember ? 'white' : 'rgb(53, 53, 111)', width: '50%', fontSize: '0.7rem', marginRight: '0.1rem' }} >None Member</Button>
                                </Box>
                                {
                                    isMember && (
                                        <Form noValidate validated={Validated} onSubmit={handleSubmitGst} >
                                            <Row className="mb-3 mt-3">
                                                <Form.Group className='mb-4' as={Col} md="6" controlId="validationCustom01">

                                                    <Form.Control
                                                        required

                                                        type="text"
                                                        className='inputField'
                                                        placeholder="Full Name*"
                                                        style={{ padding: '0.8rem', borderRadius: '0.1rem' }}
                                                        onChange={(e) => setName(e.target.value)}

                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter Valid Name
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mb-4' as={Col} md="6" controlId="validationCustom01">

                                                    <Form.Control
                                                        required

                                                        type="text"
                                                        className='inputField'
                                                        placeholder="Email*"
                                                        style={{ padding: '0.8rem', borderRadius: '0.1rem' }}
                                                        onChange={(e) => setEmail(e.target.value)}


                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter Valid email
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                {/* <Form.Group className='mb-4' as={Col} md="12" controlId="validationCustom01">

                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        className='inputField'
                                                        placeholder="Mobile*"
                                                        style={{ padding: '0.8rem', borderRadius: '0.1rem' }}
                                                        onChange={(e) => setMobile(e.target.value)}

                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter Valid mobile
                                                    </Form.Control.Feedback>
                                                </Form.Group> */}

                                                <Form.Group className='mb-4' as={Col} md="12" controlId="validationCustom01">
                                                    <PhoneInput
                                                        required
                                                        className='inputField'
                                                        placeholder="Mobile*"
                                                        style={{ padding: '0.8rem', borderRadius: '0.1rem', border: '1px solid lightgrey' }}
                                                        international
                                                        defaultCountry="IN"
                                                        value={mobile}
                                                        onChange={(value) => setMobile(value)}
                                                        inputProps={{
                                                            className: 'custom-inputField', // Use your custom class
                                                            placeholder: 'Mobile',
                                                            style: {
                                                                padding: '1.2rem',
                                                                border: 'none', // Set border to none
                                                                width: '100%',
                                                            }
                                                        }}


                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter a Valid mobile
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className='mb-4' as={Col} md="12" controlId="validationCustom01">

                                                    <select
                                                        required
                                                        type="text"
                                                        className='inputField form-select'

                                                        aria-label="Default select example"
                                                        placeholder="Message *"
                                                        style={{ padding: '0.8rem', borderRadius: '0.1rem', width: '100%', border: '1px solid lightgray' }}
                                                        onChange={(e) => {
                                                            setDestrict(e.target.value)
                                                            clubData(e.target.value)
                                                            if (e.target.value === 'Others') {
                                                                setIsClub(false)
                                                            }
                                                        }}
                                                    >
                                                        <option selected value="" disabled >District *</option>
                                                        <option>District 320 A</option>
                                                        <option>District 320 B</option>
                                                        <option>District 320 C</option>
                                                        <option>District 320 D</option>
                                                        <option>District 320 E</option>
                                                        <option>District 320 F</option>
                                                        <option>District 320 G</option>
                                                        <option >Others</option>

                                                    </select>

                                                    <Form.Control.Feedback type="invalid">
                                                        Requirement can't be empty
                                                    </Form.Control.Feedback>

                                                </Form.Group>
                                                {
                                                    isClub && (
                                                        <Form.Group className='mb-4' as={Col} md="12" controlId="validationCustom01">

                                                            <select
                                                                required
                                                                type="text"
                                                                noValidate
                                                                className='inputField form-select'

                                                                aria-label="Default select example"
                                                                placeholder="Club *"
                                                                style={{ padding: '0.8rem', borderRadius: '0.1rem', width: '100%', border: '1px solid lightgray' }}
                                                                onChange={(e) => setClub(e.target.value)}
                                                            >
                                                                <option selected value="" disabled >Club *</option>
                                                                {
                                                                    clubDrop.map((data) => {
                                                                        return (
                                                                            <option>{data.ClubNme}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>

                                                            <Form.Control.Feedback type="invalid">
                                                                Requirement can't be empty
                                                            </Form.Control.Feedback>

                                                        </Form.Group>
                                                    )
                                                }

                                                <Form.Group className='mb-4' as={Col} md="12" controlId="validationCustom01">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                                                        <div style={{ justifyContent: 'space-between', display: 'flex' }} >
                                                            <DatePicker
                                                                label='Date Of Birthday'
                                                                views={['month', 'day']}
                                                                sx={{ width: '14rem' }}
                                                                slotProps={{ textField: { variant: 'standard' } }}
                                                                onChange={(e) => {

                                                                    const localDate = new Date(e);
                                                                    const timezoneOffset = localDate.getTimezoneOffset();
                                                                    localDate.setMinutes(localDate.getMinutes() - timezoneOffset);

                                                                    const isoDateString = localDate.toISOString();

                                                                    setDob(isoDateString);
                                                                    // console.log('db', isoDateString);
                                                                }}
                                                            />


                                                            <DatePicker
                                                                label='Marriage/Special Day'
                                                                views={['month', 'day']}
                                                                sx={{ width: '14rem' }}
                                                                slotProps={{ textField: { variant: 'standard' } }}
                                                                onChange={(e) => {

                                                                    const localDate = new Date(e);
                                                                    const timezoneOffset = localDate.getTimezoneOffset();
                                                                    localDate.setMinutes(localDate.getMinutes() - timezoneOffset);

                                                                    const isoDateString = localDate.toISOString();

                                                                    setDom(isoDateString);
                                                                    // console.log('mar', isoDateString);
                                                                }}


                                                            />

                                                        </div>

                                                    </LocalizationProvider>
                                                </Form.Group>


                                                <Form.Group className='mb-4' as={Col} md="6" controlId="validationCustom01">

                                                    <select
                                                        required
                                                        type="text"
                                                        className='inputField form-select'

                                                        aria-label="Default select example"
                                                        placeholder="Club *"
                                                        style={{ padding: '0.8rem', borderRadius: '0.1rem', width: '100%', border: '1px solid lightgray' }}
                                                        onChange={(e) => setDonation(e.target.value)}
                                                    >
                                                        <option selected value="" disabled >Donate *</option>
                                                        {
                                                            donationType1.map((data) => {
                                                                return (
                                                                    <option>{data.donation}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>

                                                    <Form.Control.Feedback type="invalid">
                                                        Requirement can't be empty
                                                    </Form.Control.Feedback>

                                                </Form.Group>

                                                <Form.Group as={Col} md="6" controlId="validationCustom01">

                                                    <select
                                                        required
                                                        type="text"
                                                        className='inputField form-select'

                                                        aria-label="Default select example"
                                                        placeholder="Club *"
                                                        style={{ padding: '0.8rem', borderRadius: '0.1rem', width: '100%', border: '1px solid lightgray' }}
                                                        onChange={(e) => setArea(e.target.value)}
                                                    >
                                                        <option selected value="" disabled >Area *</option>
                                                        {
                                                            areas.map((data) => {
                                                                return (
                                                                    <option>{data.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>

                                                    <Form.Control.Feedback type="invalid">
                                                        Requirement can't be empty
                                                    </Form.Control.Feedback>

                                                </Form.Group>
                                            </Row>



                                            {
                                                isMember && (
                                                    <div style={{ marginTop: 20, position: 'relative', top: '5rem' }} >
                                                         <button disabled={isLoading} type='submit' className='signupButton' style={{ marginTop: 20 }} >{isLoading ? 'Donating..' : 'Donate'}</button>
                                                    </div>
                                                )
                                            }

                                        </Form>
                                    )
                                }

                                {
                                    !isMember && (
                                        <Form noValidate validated={Validated} onSubmit={handleSubmitGst} >
                                            <Row className="mb-3 mt-3">
                                                <Form.Group className='mb-4' as={Col} md="6" controlId="validationCustom01">

                                                    <Form.Control
                                                        required

                                                        type="text"
                                                        className='inputField'
                                                        placeholder="Full Name*"
                                                        style={{ padding: '0.8rem', borderRadius: '0.1rem' }}
                                                        onChange={(e) => setName(e.target.value)}

                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter Valid Name
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group className='mb-4' as={Col} md="6" controlId="validationCustom01">

                                                    <Form.Control
                                                        required

                                                        type="text"
                                                        className='inputField'
                                                        placeholder="Email*"
                                                        style={{ padding: '0.8rem', borderRadius: '0.1rem' }}
                                                        onChange={(e) => setEmail(e.target.value)}


                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter Valid email
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                {/* <Form.Group className='mb-4' as={Col} md="12" controlId="validationCustom01">

                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        className='inputField'
                                                        placeholder="Mobile*"
                                                        style={{ padding: '0.8rem', borderRadius: '0.1rem' }}
                                                        onChange={(e) => setMobile(e.target.value)}

                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter Valid mobile
                                                    </Form.Control.Feedback>
                                                </Form.Group> */}
                                                <Form.Group className='mb-4' as={Col} md="12" controlId="validationCustom01">
                                                    <PhoneInput
                                                        required
                                                        className='inputField'
                                                        placeholder="Mobile*"
                                                        style={{ padding: '0.8rem', borderRadius: '0.1rem', border: '1px solid lightgrey' }}
                                                        international
                                                        defaultCountry="IN"
                                                        value={mobile}
                                                        onChange={(value) => setMobile(value)}
                                                        inputProps={{
                                                            className: 'custom-inputField',
                                                            placeholder: 'Mobile',
                                                            style: {
                                                                padding: '1.2rem',
                                                                border: 'none',
                                                                width: '100%',
                                                            }
                                                        }}


                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        Please Enter a Valid mobile
                                                    </Form.Control.Feedback>
                                                </Form.Group>

                                                <Form.Group className='mb-4' as={Col} md="12" controlId="validationCustom01">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>

                                                        <div style={{ justifyContent: 'space-between', display: 'flex' }} >
                                                            <DatePicker
                                                                label='Date Of Birthday'
                                                                views={['month', 'day']}
                                                                sx={{ width: '14rem' }}
                                                                slotProps={{ textField: { variant: 'standard' } }}
                                                                onChange={(e) => setDom(e)}
                                                            />

                                                            <DatePicker
                                                                label='Marriage/Special Day'
                                                                views={['month', 'day']}
                                                                sx={{ width: '14rem' }}
                                                                slotProps={{ textField: { variant: 'standard' } }}
                                                                onChange={(e) => setDom(e)}
                                                            />
                                                        </div>

                                                    </LocalizationProvider>
                                                </Form.Group>

                                                <Form.Group className='mb-4' as={Col} md="6" controlId="validationCustom01">
                                                    <select
                                                        required
                                                        type="text"
                                                        className='inputField form-select'

                                                        aria-label="Default select example"
                                                        placeholder="Club *"
                                                        style={{ padding: '0.8rem', borderRadius: '0.1rem', width: '100%', border: '1px solid lightgray' }}
                                                        onChange={(e) => setDonation(e.target.value)}
                                                    >
                                                        <option selected value="" disabled >Donate *</option>
                                                        {
                                                            donationType1.map((data) => {
                                                                return (
                                                                    <option>{data.donation}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>

                                                    <Form.Control.Feedback type="invalid">
                                                        Requirement can't be empty
                                                    </Form.Control.Feedback>

                                                </Form.Group>

                                                <Form.Group as={Col} md="6" controlId="validationCustom01">

                                                    <select
                                                        required
                                                        type="text"
                                                        className='inputField form-select'

                                                        aria-label="Default select example"
                                                        placeholder="Club *"
                                                        style={{ padding: '0.8rem', borderRadius: '0.1rem', width: '100%', border: '1px solid lightgray' }}
                                                        onChange={(e) => setArea(e.target.value)}
                                                    >
                                                        <option selected value="" disabled >Area *</option>
                                                        {
                                                            areas.map((data) => {
                                                                return (
                                                                    <option>{data.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>

                                                    <Form.Control.Feedback type="invalid">
                                                        Requirement can't be empty
                                                    </Form.Control.Feedback>

                                                </Form.Group>
                                            </Row>

                                            {
                                                !isMember && (
                                                    <div style={{ marginTop: 20, position: 'relative', top: '4.9rem' }} >
                                                        <button disabled={isLoading} type='submit' className='signupButton' style={{ marginTop: 20 }} >{isLoading ? 'Donating..' : 'Donate'}</button>
                                                    </div>
                                                )
                                            }
                                        </Form>
                                    )
                                }

                                <div style={{ width: '100%', marginTop: 12, position: 'relative', top: '-4rem' }} >
                                    <ReCAPTCHA
                                        className='captcha'
                                        sitekey="6Lda_VUpAAAAAGw6Ezt4QR3xAaZ4KJfIK2kmyUOk"
                                    />
                                </div>

                            </DialogContentText>
                        </DialogContent>
                    )
                }

                {
                    isSelect && (
                        <Box sx={{ flexGrow: 1 }}>
                            <div style={{ width: '25rem', padding: 22, paddingTop: 53, paddingBottom: 42 }} >
                                <Typography style={{ fontSize: 15 }} >
                                    Donate  Now .. Celebrate  Birthday or any Special Occasion of yours or your Family & Friends
                                </Typography>
                                <br />
                                <Typography style={{ fontSize: 19, fontWeight: '600', marginBottom: 10 }} >Food Donation Package</Typography>
                                <Grid container spacing={2}>
                                    
                                    <Grid className='mealsCon' md={12}>
                                        <Item onClick={() => {
                                            setIsSelectText3(false)
                                            setIsSelectText2(true)
                                            setPayText(5000)
                                            setText('Extra Meals - Three Items ( eg Roti/Poori Curry , Sambar Rice ) Rs. 5000')
                                        }} className='textCon' style={{ opacity: isSelectText2 ? 0.6 : 1 }} >Extra Meals - Three Items ( eg Roti/Poori Curry , Sambar Rice ) Rs. 5000</Item>
                                    </Grid>
                                    <Grid className='mealsCon' md={12}>
                                        <Item onClick={() => {
                                            setIsSelectText2(false)
                                            setIsSelectText3(true)
                                            setPayText(8000)
                                            setText('Full Special  Meals – Five Items ( eg Roti/Poori, Curry, Rice Sambar , Sweet) Rs. 8000 (US $ 100)')
                                        }} className='textCon' style={{ opacity: isSelectText3 ? 0.6 : 1 }} >Full Special  Meals – Five Items ( eg Roti/Poori, Curry, Rice Sambar , Sweet) Rs. 8000 (US $ 100)</Item>
                                    </Grid>
                                    <br />
                                    <Typography style={{ marginLeft: 8 }} >All for About 250 People Hot Served </Typography>
                                </Grid>
                                <button onClick={() => valText()} className='signupButton' style={{ width: '100%', marginTop: 20 }} >Submit</button>
                            </div>
                        </Box>
                    )
                }
            </Dialog>
            {/* <Donateqr payUrl = {payUrl} payNow={payNow} open={open} setOpen={setOpen} /> */}
        </div>
    );
}
import React, { useEffect, useState } from "react";
import { FaFacebook, FaInstagram, FaMapMarkerAlt, FaPhone, FaTwitter, FaWhatsapp, FaYoutube } from "react-icons/fa";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { Typography } from "@mui/material";
import { useDispatch } from 'react-redux';
import { OpenDonateBox, OpenDrawer, OpenUploadBox } from '../UserRedux/Action/StateAction';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import {  makeStyles } from '@material-ui/core';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const useStyles = makeStyles((theme) => ({
    button: {
      margin: theme.spacing(2),
    },
    dialogContent: {
      padding: theme.spacing(2),
    },
    paragraph: {
      marginBottom: theme.spacing(2),
    },
  }));

const Footer = () => {

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [open1, setOpen1] = React.useState(false);

  const handleClickOpen1 = () => {
    setOpen1(true);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowWidth <= 600;

  const commonStyles = {
    fontSize: isMobile ? '15px' : '24px',
    fontWeight: '100',
    color: 'white',
    cursor: 'pointer',
    marginRight: isMobile ? '7px' : '20px',
  };

    return (
        <div style={{ width: '100%', backgroundColor: 'black', marginTop: '205px' }} >
            <div style={{ height: '10.5rem', width: '3rem', backgroundColor: 'white', position: 'fixed', bottom: 0, right: 5, zIndex: 1000 }}  >
                <a href="mailto:lionsmow@gmail.com" target="_blank" >
                    <div style={{ width: '4rem', backgroundColor: 'rgb(150, 59, 59)', height: '3.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: 9.1 }} >
                        <MailOutlineIcon style={{ color: 'white', fontSize: 24.5 }} />
                    </div>
                </a>
                <a href="tel:+919440081628" >
                    <div style={{ width: '4rem', backgroundColor: 'rgb(10, 71, 224)', height: '3.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: 9.1 }} >
                        <FaPhone style={{ fontSize: 24.5, color: 'white' }} />
                    </div>
                </a>
                <a href="https://api.whatsapp.com/send?phone=9440081628" target="_blank" >
                    <div style={{ width: '4rem', backgroundColor: 'green', height: '3.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: 9.1 }} >
                        <FaWhatsapp style={{ color: 'white', fontSize: 24.5 }} />
                    </div>
                </a>
            </div>
            <div className="container" >
                <div className="row p-1 pt-5 pb-5 p-lg-5 gy-3" >
                    <div className="col-12 col-md-5 col-xl-7 mx-auto" >
                        <div style={{display:'flex', justifyContent:'space-between'}}>
                        <p style={{ fontSize: 24, fontWeight: '900', color: 'white' }} >Contact Us</p>
                        {/* <p style={{ fontSize: 24, fontWeight: '100', color: 'white', cursor:'pointer', marginRight:120}} onClick={handleClickOpen} >Terms & Conditions</p> */}
                        </div>
                        <div style={{ width: '4rem', height: '0.18rem', backgroundColor: 'white', position: 'relative', zIndex: 50 }} ></div>
                        <img src={require('../../Container/images/mow.png')} style={{ width: '120px' }} />
                        <p className="mt-3" >
                            <div className="d-flex mb-5" >
                                <FaMapMarkerAlt style={{ color: 'red', fontSize: 28, position: 'relative', top: '1rem' }} />
                                <Typography style={{ marginTop: 17, fontSize: 16.5, fontWeight: '500', marginLeft: 5, color: 'white' }} >
                                    Lions Club Bhavan & Guest House 1-8-179, Lakhpath Building, Near Paradise Circle, MG Road, PG Road, Jogani, Ramgopalpet, Secunderabad, Telangana
                                </Typography>
                            </div>

                            <p style={{ marginTop: 0, fontSize: 16.5, fontWeight: '500', marginLeft: 5, color: 'white' }} ><LocalPhoneIcon style={{ fontSize: 19, color: 'red', position: 'relative', top: '-0.1rem' }} /> +91 94400 81628</p>
                            <p style={{ fontSize: 16.5, fontWeight: '500', marginLeft: 5, color: 'white' }} ><MailOutlineIcon style={{ fontSize: 19, color: 'red', position: 'relative', top: '-0.1rem' }} /> lionsmow@gmail.com</p>
                        </p>
                    </div>

                    

                    <div className="col-12 col-md-5 col-xl-5 mx-auto " >
                        <p style={{ fontSize: 24, fontWeight: '900', color: 'white' }} >Fallow  Us</p>
                        <div style={{ width: '4rem', height: '0.18rem', backgroundColor: 'white', position: 'relative' }} ></div>
                        <br />
                        <div className="icons">
                            <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0.2rem', border: '1.2px solid white' }} >
                                <a style={{ color: 'white' }} href="https://www.facebook.com/lionsmealsonwheels" target="_blank" ><FaFacebook className="icon" /></a>
                            </div>
                            <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0.2rem', border: '1.2px solid white' }} >
                                <a style={{ color: 'white' }} href="https://www.instagram.com/lionsmealsonwheels?igsh=Zzd4bjcxd3BmdGx3" target="_blank" ><FaInstagram className="icon" /></a>
                            </div>
                            {/* <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0.2rem', border: '1.2px solid white' }} >
                                <a style={{ color: 'white' }} href="#" target="_blank" ><FaTwitter className="icon" /></a>
                            </div> */}
                            <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0.2rem', border: '1.2px solid white' }} >
                                <a style={{ color: 'white' }} href="https://youtube.com/@brandmd320?si=_mekNdga2-rfpMX-" target="_blank" ><FaYoutube className="icon" /></a>
                            </div>
                        </div>
                    </div>
                    <div style={{display:'flex', flexDirection: 'row', flexWrap:'wrap'}}>
                    <p className="py-4" style={commonStyles} onClick={handleClickOpen} >Terms & Conditions</p>
                    <p className="py-4" style={commonStyles} onClick={handleClickOpen1} >Cancellation Policy</p>
                    </div>

                    <div className="col-12  ps-lg-4 pe-lg-4" >
                        <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="250" id="gmap_canvas" src="https://maps.google.com/maps?q=Lions%20Club%20Bhavan%20&%20Guest%20House%201-8-179,%20Lakhpath%20Building,%20Near%20Paradise%20Circle,%20MG%20Road,%20PG%20Road,%20Jogani,%20Ramgopalpet,%20Secunderabad,%20Telangana&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div></div>
                    </div>
                    <div className="col-12 ps-lg-4 pe-lg-4" >
                        <div style={{ width: '100%', height: '0.12rem', backgroundColor: 'white' }} ></div>
                        <Typography style={{ textAlign: 'center', fontSize: 14, color: 'white', marginTop: 42 }} >@ Meals On wheels 2023. Allright reserved</Typography>
                    </div>
                </div>
            </div>

            {/* <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        maxWidth='lg'
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle><h4>Terms of Service for Lions meals on wheels Website</h4></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            


<h5>1. Acceptance of Terms</h5>

By accessing and using the Lions meals on wheels website, you agree to comply with and be bound by these Terms of Service. If you do not agree with any part of these terms, you may not use the website. <br/>

<h5>2. Use of Website</h5>

<p>2.1. You agree to use the Lions meals on wheels website for lawful purposes only.</p> 

2.2. You may not use the website in any way that could impair its performance, damage its content, or interfere with any other party's use of the website. <br/>

<h5>3. Intellectual Property</h5>

3.1. All content on the Lions meals on wheels website, including text, graphics, logos, images, and software, is the property of Lions meals on wheels and is protected by intellectual property laws. <br/>

3.2. You may not reproduce, distribute, or display any content from the website without the express written permission of Lions meals on wheels. <br/>

<h5>4. User Contributions</h5>

4.1. Users may contribute content to the website, such as comments, testimonials, or other information.<br/>

4.2. By contributing content, you grant Lions meals on wheels a non-exclusive, royalty-free, perpetual, and worldwide license to use, modify, and display the content. <br/>

<h5>5. Privacy Policy</h5>

5.1. Our Privacy Policy governs the collection, use, and disclosure of your personal information. By using the website, you agree to the terms outlined in our Privacy Policy.<br/>

<h5>6. Links to Third-Party Websites</h5>

6.1. The Lions meals on wheels website may contain links to third-party websites. These links are provided for your convenience, and Lions meals on wheels is not responsible for the content or privacy practices of these websites. <br/>

<h5>7. Limitation of Liability</h5>

7.1. Lions meals on wheels is not liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with the use of the website.<br/>

<h5>8. Changes to Terms of Service</h5>

8.1. Lions meals on wheels reserves the right to modify or update these Terms of Service at any time. It is your responsibility to review the terms periodically.<br/>

<h5>9. Governing Law</h5>

9.1. These Terms of Service are governed by and construed in accordance with the laws of Your Country/Region.<br/>

<h5>10. Contact Information</h5>

10.1. For any questions regarding these Terms of Service, please contact us at lionsmow@gmail.com.

---

Remember, it's crucial to tailor these terms to your specific NGO's needs and consult with legal professionals to ensure compliance with applicable laws and regulations.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Disagree</Button>
          <Button onClick={handleClose}>Agree</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment> */}


<React.Fragment>
      {/* <Button variant="outlined" color="primary" className={classes.button} onClick={handleClickOpen}>
      Terms of Service for Lions meals on wheels Website
      </Button> */}
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title"  aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <h2>Terms of Service for Lions meals on wheels Website</h2>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.paragraph}>
            <Typography variant="h6">1. Acceptance of Terms</Typography>
            <Typography>
            By accessing and using the Lions meals on wheels website, you agree to comply with and be bound by these Terms of Service. If you do not agree with any part of these terms, you may not use the website.
            </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography variant="h6">2. Use of Website</Typography>
            <Typography>
              2.1. You agree to use the Lions Meals on Wheels website for lawful purposes only.
            </Typography>
            <Typography>
              2.2. You may not use the website in any way that could impair its performance, damage its content, or
              interfere with any other party's use of the website.
            </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography variant="h6">3. Intellectual Property</Typography>
            <Typography>
            3.1. All content on the Lions meals on wheels website, including text, graphics, logos, images, and software, is the property of Lions meals on wheels and is protected by intellectual property laws.
            </Typography>
            <Typography>
            3.2. You may not reproduce, distribute, or display any content from the website without the express written permission of Lions meals on wheels.
            </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography variant="h6">4. User Contributions</Typography>
            <Typography>
            4.1. Users may contribute content to the website, such as comments, testimonials, or other information.
            </Typography>
            <Typography>
            4.2. By contributing content, you grant Lions meals on wheels a non-exclusive, royalty-free, perpetual, and worldwide license to use, modify, and display the content.
            </Typography>
          </div>
          
          <div className={classes.paragraph}>
            <Typography variant="h6">5. Privacy Policy</Typography>
            <Typography>
            5.1. Our Privacy Policy governs the collection, use, and disclosure of your personal information. By using the website, you agree to the terms outlined in our Privacy Policy.
            </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography variant="h6">6. Links to Third-Party Websites</Typography>
            <Typography>
            6.1. The Lions meals on wheels website may contain links to third-party websites. These links are provided for your convenience, and Lions meals on wheels is not responsible for the content or privacy practices of these websites.            </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography variant="h6">7. Limitation of Liability</Typography>
            <Typography>
            7.1. Lions meals on wheels is not liable for any direct, indirect, incidental, consequential, or special damages arising out of or in any way connected with the use of the website.
             </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography variant="h6">8. Changes to Terms of Service</Typography>
            <Typography>
            8.1. Lions meals on wheels reserves the right to modify or update these Terms of Service at any time. It is your responsibility to review the terms periodically.
         </Typography>
          </div>

          <div className={classes.paragraph}>
            <Typography variant="h6">9. Governing Law</Typography>
            <Typography>
            9.1. These Terms of Service are governed by and construed in accordance with the laws of Your Country/Region.         </Typography>
          </div>


          {/* Add more sections as needed */}

          <div className={classes.paragraph}>
            <Typography variant="h6">10. Contact Information</Typography>
            <Typography>
              10.1. For any questions regarding these Terms of Service, please contact us at lionsmow@gmail.com.
            </Typography>
          </div>

          <Typography>
          Remember, it's crucial to tailor these terms to your specific NGO's needs and consult with legal professionals to ensure compliance with applicable laws and regulations.
            </Typography>
        </DialogContent>
      </Dialog>
    </React.Fragment>

    <React.Fragment>
      {/* <Button variant="outlined" color="primary" className={classes.button} onClick={handleClickOpen}>
      Terms of Service for Lions meals on wheels Website
      </Button> */}
      <Dialog open={open1} onClose={handleClose1} aria-labelledby="alert-dialog-title"  aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <h2>Cancellation and Refund Policy</h2>
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <div className={classes.paragraph}>
            
            <Typography>
            As its a volountary donations for feeding the poor there is NO REFUND happening.
            </Typography>
          </div>

          
        </DialogContent>
      </Dialog>
    </React.Fragment>
        </div>
    );
};

export default Footer;

import { StateUpdate } from "../StateUpdate/StateUpdate";
import { combineReducers } from 'redux'
import { DrawerUpdate } from "../StateUpdate/StateUpdate";
import { UploadImage } from "../StateUpdate/StateUpdate";

export const rootReducer = combineReducers({
    StateUpdate: StateUpdate,
    DrawerUpdate: DrawerUpdate,
    UploadImage:UploadImage

})


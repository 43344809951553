import React, { useState } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Avatar,
  Grid,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios"; // Import Axios

// import adminlogin from "../images/adminlogin.png";
import "../Component/AdminLoginPage.css";
import mow from '../images/mow.png'
import NavigationTop from "./Navigation";

const AdminLoginPage = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [district, setDistrict] = useState("");
  const navigate = useNavigate();

  const mainAdmin = {
    email: "mainadmin@gmail.com",
    password: "mainadmin@123",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get(
        "https://mealsapi.emedha.in/admin/admin-login",
        {
          params: { email, password, district },
        }
      );

      // https://mealsapi.emedha.in/donate/v1/donation

      const data = response.data;

      if (response.status === 200) {
        sessionStorage.setItem("adminEmail", email);
        sessionStorage.setItem("password", password);
        sessionStorage.setItem("district", district);
        sessionStorage.setItem("isMainAdmin", email === mainAdmin.email);
        navigate("/admin-panel");
      } else {
        alert(data.message);
      }
    } catch (error) {
      console.error("Error logging in:", error);
      alert("An error occurred while logging in. Please try again later.");
    }
  };

  return (
    <>
      {/* <NavigationTop /> */}
      <div className="login-main-container">
        <div className="login-container">
        <img src={require('../images/mow.png')} style={{ width: '150px', height:'auto',position: 'relative', top: '-0.1rem',alignSelf:"flex-end",marginRight:'50px' }} />
          <form className="form-container" onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              {email !== mainAdmin.email && (
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <InputLabel id="district-label">District *</InputLabel>
                    <Select
                      labelId="district-label"
                      id="district"
                      name="district"
                      value={district}
                      onChange={(e) => setDistrict(e.target.value)}
                      label="District *"
                      required
                    >
                      <MenuItem value="" disabled>
                        District *
                      </MenuItem>
                      <MenuItem value="District 320 A">District 320 A</MenuItem>
                      <MenuItem value="District 320 B">District 320 B</MenuItem>
                      <MenuItem value="District 320 C">District 320 C</MenuItem>
                      <MenuItem value="District 320 D">District 320 D</MenuItem>
                      <MenuItem value="District 320 E">District 320 E</MenuItem>
                      <MenuItem value="District 320 F">District 320 F</MenuItem>
                      <MenuItem value="District 320 G">District 320 G</MenuItem>
                      <MenuItem value="District 320 H">District 320 H</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  style={{ marginTop: "1rem" }}
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </>
  );
};

export default AdminLoginPage;

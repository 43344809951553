import "./App.css";
import { Home } from "./Container/WebScreen/Home/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap";
import Footer from "./Container/Component/Footer";
import Gallery from "./Container/WebScreen/Home/Gallery";
import { Video } from "./Container/WebScreen/Home/Video";
import AdminHome from "./AdminContainer/AdminHome";
import Donateqr from "./Container/Component/Donateqr";
import AdminLoginPage from "./Container/Component/AdminLoginPage";
import ProtectedRoute from "./Container/Component/ProtectedRoute";
import DonateForm from "./Container/Component/DonateForm";
import PaymentStatus from "./Container/Component/PaymentStatus";


function App() {
  return (
    <>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/donate-qr" element={<Donateqr />}></Route>
            <Route path="/donate" element={<DonateForm />} />
            <Route path="/" element={<Home />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/video" element={<Video />} />
            <Route path="/admin-login" element={<AdminLoginPage />} />
            <Route path="/admin-panel" element={<ProtectedRoute element={<AdminHome />} />}/>
            <Route path='/payment/success/:merchantTransactionId/:mtId' element={<PaymentStatus/>}/>
          </Routes>
          {/* <Footer /> */}
        </BrowserRouter>
      </div>
    
    </>
  );
}

export default App;

import React from 'react'
import SideBar from './SideBar'
import NavigationTop from '../../Component/Navigation'

export const Video = () => {
    return (
        <div style={{ marginTop: '8rem', padding: 7, }}>
            <NavigationTop />
            <SideBar />
            <div className='d-flex justify-content-center flex-wrap gap-4'>

            <iframe style={{ width: '33.3333%'  }} height="315" src="https://www.youtube.com/embed/q1udURve7vQ?si=sqGlVPTwKrsd_PoJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/zVzvZYlzRn4?si=ATvKz8obGg70_cSF" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/BdpNxAUQ6tw?si=fRfmRtnRjsohLLpy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/qY9f5c7KWy8?si=HPR5kKJ10OpKarmf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/KT5DR1CSBn8?si=f9ICAD-Y5t7zja94" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/zU7omDIokXA?si=Lryz2uxYSdwuY5ZH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

            <iframe  style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/RqH2Dw3Zdvg?si=3IzHQu7PKmkK-NP1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

            <iframe  style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/VBjlCGsrg-A?si=hcVa2XC6rOgBs-tJ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/4WV_UNfsY40?si=OemE-jaYFEt59Mts" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/7SKoUTdOMiA?si=Pay_uRrFpQ8Fy1_H" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/iGg2A1T1jOI?si=Ko0p0dW5gravQvRp" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/BZIC-2bB81Q?si=WzRvrRMYY3fReEwc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/jA47A8p3R44?si=SQNmg_kmgeWegwJX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/L9HYuBtt7uc?si=g7CJRZ-kFIJqEBc5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/OhMvH9U5nXE?si=vix8Xn4sb-uFKCyO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/P9uvfVCogFs?si=AdfnYMlJeupanRFl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/qC-7Ox_Cieo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/q1udURve7vQ?si=j_m9QEovDUArAgo1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/UauNBsBfmv0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/DQN0_nKIZjg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/_se3c-r-opE?si=zgURUFpB0ydAIhA9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/vc7GYBa3URI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/rv_DoZoJ7o8?si=s71xqgSxAZkAIiDQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/Eaz2m6p_VJg?si=xmo6nNVEbYo_gjwO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <iframe style={{ width: '33.3333%' }} height="315" src="https://www.youtube.com/embed/N07mXeRUP_4?si=Bpguik38nvBXDmLf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
           
            
            </div>
        </div>
    )
}
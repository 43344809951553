import React, { useEffect, useState } from 'react';
import NavigationTop from './Navigation';

const PaymentStatus = () => {
  const [paymentId, setPaymentId] = useState(null);
  const [token, setToken] = useState(null);
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const url = window.location.pathname;
    const segments = url.split('/');
    const merchantTransactionId = segments[segments.length - 2];
    const mtId = segments[segments.length - 1];

    setPaymentId(merchantTransactionId);
    setToken(mtId);

    async function fetchStatus() {
      try {
        const response = await fetch(`https://mealsapi.emedha.in/pay/payment/success/${merchantTransactionId}/${mtId}`, {
          method: 'POST', // Correct HTTP method
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok) {
          const result = await response.text();
          setStatus(result);
        } else {
          setStatus('Failed to fetch payment status');
        }
      } catch (error) {
        console.error('Error fetching payment status:', error);
        setStatus('Error fetching payment status');
      } finally {
        setLoading(false);
      }
    }

    fetchStatus();
  }, []); // Consider adding dependencies if necessary

  if (loading) {
    return <div>Loading...</div>;
  }

  return (

    <>
    <NavigationTop />
    <div style={{display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',marginTop:'120px'}}>
        <div>
      <h1>Payment Status</h1>
      <p><strong>Transaction ID:</strong> {paymentId}</p>
      <div dangerouslySetInnerHTML={{ __html: status }}></div>
      </div>
    </div>
    </>
  );
};



export default PaymentStatus;



// import * as React from "react";
// import axios from "axios";
// import AdminNavigation from "../Container/Component/AdminNavigation";
// import AdminSidebar from "./AdminSidebar";
// import Pagination from "@mui/material/Pagination";

// import "./index.css";

// import Table from "react-bootstrap/Table";

// function AdminHome() {
//   const [images, setImages] = React.useState([]);
//   const [donateList, setDonateList] = React.useState([]);

//   const [activeTabImages, setActiveTabImage] = React.useState(true);
//   const [activeTabDonate, setActiveTabDonate] = React.useState(false);
//   const [district, setDistrict] = React.useState("");
//   const [isMainAdmin, setIsMainAdmin] = React.useState(false);
//   const [currentPage, setCurrentPage] = React.useState(1);
//   const itemsPerPage = 10;

//   const handleChangePage = (event, newPage) => {
//     setCurrentPage(newPage);
//   };

//   // Calculate the records to display for the current page
//   const indexOfLastRecord = currentPage * itemsPerPage;
//   const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
//   const currentRecords = donateList.slice(
//     indexOfFirstRecord,
//     indexOfLastRecord
//   );
//   const totalPages = Math.ceil(donateList.length / itemsPerPage);

//   React.useEffect(() => {
//     const storedDistrict = sessionStorage.getItem("district");
//     const mainAdminStatus = sessionStorage.getItem("isMainAdmin") === "true";
//     if (storedDistrict) {
//       setDistrict(storedDistrict);
//     }
//     setIsMainAdmin(mainAdminStatus);
//   }, []);

//   const imagesItem = () => {
//     axios.post("https://mealsapi.emedha.in/upload/show-image").then((res) => {
//       setImages(res.data);
//     });
//   };

//   const handleTabClickImage = () => {
//     setActiveTabImage(true);
//     setActiveTabDonate(false);
//   };

//   const handleTabClickDonate = () => {
//     setActiveTabDonate(true);
//     setActiveTabImage(false);
//   };

//   const getDonateData = () => {
//     axios.get("https://mealsapi.emedha.in/donate/donate-data").then((res) => {
//       if (isMainAdmin) {
//         setDonateList(res.data.data);
//       } else {
//         const filteredData = res.data.data.filter(
//           (item) => item.dist === district
//         );
//         setDonateList(filteredData);
//       }
//     });
//   };
//   React.useEffect(() => {
//     imagesItem();
//     getDonateData();
//   }, [district, isMainAdmin]);

//   return (
//     <div>
//       <AdminNavigation />
//       <AdminSidebar />
//       <div className="sidebar">
//         <div className="tab" id="imagesTab" onClick={handleTabClickImage}>
//           Images
//         </div>
//         <div className="tab" id="donateTab" onClick={handleTabClickDonate}>
//           Donate Details
//         </div>
//       </div>

//       {activeTabImages && (
//         <div
//           style={{
//             marginTop: 50,
//             padding: 20,
//             display: "flex",
//             flexWrap: "wrap",
//           }}
//           className="images-container"
//         >
//           {images.map((data) => {
//             return (
//               <div
//                 style={{
//                   width: "33.33333333333333%",
//                   borderRadius: 2,
//                   padding: 13,
//                   borderTop: "1px solid lightgray",
//                 }}
//               >
//                 <img
//                   src={data.image}
//                   className="mb-2"
//                   style={{ width: "100%" }}
//                 />
//                 <div
//                   style={{
//                     display: "flex",
//                     justifyContent: "space-between",
//                     marginTop: 20,
//                     alignItems: "center",
//                   }}
//                 >
//                   <button
//                     style={{
//                       border: "2px solid green",
//                       color: "green",
//                       fontWeight: "600",
//                       width: "31%",
//                       height: "2.3rem",
//                       borderRadius: "3rem",
//                       backgroundColor: "white",
//                     }}
//                   >
//                     Approve
//                   </button>
//                   {/* <button
//                     style={{
//                       border: "2px solid gold",
//                       color: "gold",
//                       fontWeight: "600",
//                       width: "31%",
//                       height: "2.3rem",
//                       borderRadius: "3rem",
//                       backgroundColor: "white",
//                     }}
//                   >
//                     Update
//                   </button> */}
//                   <button
//                     style={{
//                       border: "2px solid red",
//                       color: "red",
//                       fontWeight: "600",
//                       width: "31%",
//                       height: "2.3rem",
//                       borderRadius: "3rem",
//                       backgroundColor: "white",
//                     }}
//                   >
//                     Delete
//                   </button>
//                 </div>
//               </div>
//             );
//           })}
//         </div>
//       )}
//       {activeTabDonate && (
//         <div
//           style={{
//             marginTop: "120px",
//             display: "flex",
//             flexDirection: "column",
//             justifyContent: "center",
//           }}
//           className="donate-container"
//         >
//           <h1 style={{ margin: "25px" }}>Donate Details</h1>

//           <Table
//             striped
//             bordered
//             hover
//             responsive
//             style={{ margin: "25px", marginRight: "25px" }}
//           >
//             <thead>
//               <tr>
//                 <th>Name</th>
//                 <th>Email</th>
//                 <th>Mobile</th>
//                 <th>District</th>
//                 <th>DonationType</th>
//                 <th>Area</th>
//               </tr>
//             </thead>
//             {currentRecords.map((item) => (
//               <tbody key={item.id}>
//                 <tr>
//                   <td>{item.name}</td>
//                   <td>{item.email}</td>
//                   <td>{item.mobile}</td>
//                   <td>{item.dist}</td>
//                   <td>{item.donateType}</td>
//                   <td>{item.area}</td>
//                 </tr>
//               </tbody>
//             ))}
//           </Table>
//           <Pagination
//             count={totalPages}
//             page={currentPage}
//             onChange={handleChangePage}
//             style={{
//               display: "flex",
//               justifyContent: "center",
//               marginBottom: "25px",
//             }}
//           />
//         </div>
//       )}
//     </div>
//   );
// }

// export default AdminHome;

import React from "react";
import axios from "axios";
import { Table } from "@material-ui/core";
import Pagination from "@mui/material/Pagination";
import AdminNavigation from "../Container/Component/AdminNavigation";
import AdminSidebar from "./AdminSidebar";
import NavigationTop from "../Container/Component/Navigation";
import { Fade, Typography } from "@material-ui/core";
import LightGallery from "lightgallery/react";
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgShare from "lightgallery/plugins/share";
import lgRotate from "lightgallery/plugins/rotate";
import "./index.css";

function AdminHome() {
  const [images, setImages] = React.useState([]);
  const [donateList, setDonateList] = React.useState([]);
  const [galleryImages, setGalleryImages] = React.useState([]);

  const [approvedImageIds, setApprovedImageIds] = React.useState([]);
  const [activeTabImages, setActiveTabImage] = React.useState(true);
  const [activeTabDonate, setActiveTabDonate] = React.useState(false);
  const [district, setDistrict] = React.useState("");
  const [isMainAdmin, setIsMainAdmin] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const itemsPerPage = 10;

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  // Calculate the records to display for the current page
  const indexOfLastRecord = currentPage * itemsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - itemsPerPage;
  const currentRecords = donateList.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const totalPages = Math.ceil(donateList.length / itemsPerPage);

  React.useEffect(() => {
    const storedDistrict = sessionStorage.getItem("district");
    const mainAdminStatus = sessionStorage.getItem("isMainAdmin") === "true";
    if (storedDistrict) {
      setDistrict(storedDistrict);
    }
    setIsMainAdmin(mainAdminStatus);
  }, []);

  const fetchImages = () => {
    axios.get("https://mealsapi.emedha.in/upload/get-images").then((res) => {
      setImages(res.data);
    });
  };

  const fetchGalleryImages = () => {
    axios.get("https://mealsapi.emedha.in/upload/get-approved-images").then((res) => {
      setGalleryImages(res.data);
    });
  };

  const handleTabClickImage = () => {
    setActiveTabImage(true);
    setActiveTabDonate(false);
  };

  const handleTabClickDonate = () => {
    setActiveTabDonate(true);
    setActiveTabImage(false);
  };

  const getDonateData = () => {
    axios.get("https://mealsapi.emedha.in/donate/donate-data").then((res) => {
      if (isMainAdmin) {
        setDonateList(res.data.data);
      } else {
        const filteredData = res.data.data.filter(
          (item) => item.dist === district
        );
        setDonateList(filteredData);
      }
    });
  };

  const handleApprove = (id) => {
    axios.post(`https://mealsapi.emedha.in/upload/approve-image/${id}`).then(() => {
      // fetchImages();
      setImages((prevImages) => prevImages.filter((image) => image.id !== id));
      fetchGalleryImages();
      // Store approved image ID in local storage
      setApprovedImageIds((prevIds) => [...prevIds, id]);
      localStorage.setItem('approvedImageIds', JSON.stringify(approvedImageIds));
    });
  };

  const handleDelete = (id) => {
    axios.delete(`https://mealsapi.emedha.in/upload/delete-image/${id}`).then(() => {
      setImages((prevImages) => prevImages.filter((image) => image.id !== id));
      setGalleryImages((prevImages) => prevImages.filter((image) => image.id !== id));
      // Remove approved image ID from local storage
      setApprovedImageIds((prevIds) => prevIds.filter((imageId) => imageId !== id));
      localStorage.setItem('approvedImageIds', JSON.stringify(approvedImageIds));
    });
  };

  React.useEffect(() => {
    fetchImages();
    fetchGalleryImages();
    getDonateData();

    const storedIds = JSON.parse(localStorage.getItem('approvedImageIds')) || [];
    setApprovedImageIds(storedIds);
    const filteredGalleryImages = galleryImages.filter((image) => storedIds.includes(image.id));
    setGalleryImages(filteredGalleryImages);
  
  }, [district, isMainAdmin]);

  return (
    <div>
      <AdminNavigation />
      <AdminSidebar />
      <div className="sidebar">
        <div className="tab" id="imagesTab" onClick={handleTabClickImage}>
          Images
        </div>
        <div className="tab" id="donateTab" onClick={handleTabClickDonate}>
          Donate Details
        </div>
      </div>

      {activeTabImages && (
        <div
          style={{
            marginTop: 50,
            padding: 20,
            display: "flex",
            flexWrap: "wrap",
          }}
          className="images-container"
        >
          {images.map((data) => (
            <div
              key={data.id}
              style={{
                width: "33.33333333333333%",
                borderRadius: 2,
                padding: 13,
                borderTop: "1px solid lightgray",
              }}
            >
              <img
                src={data.image}
                className="mb-2"
                style={{ width: "100%" }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: 20,
                  alignItems: "center",
                }}
              >
                <button
                  onClick={() => handleApprove(data.id)}
                  style={{
                    border: "2px solid green",
                    color: "green",
                    fontWeight: "600",
                    width: "31%",
                    height: "2.3rem",
                    borderRadius: "3rem",
                    backgroundColor: "white",
                  }}
                >
                  Approve
                </button>
                <button
                  onClick={() => handleDelete(data.id)}
                  style={{
                    border: "2px solid red",
                    color: "red",
                    fontWeight: "600",
                    width: "31%",
                    height: "2.3rem",
                    borderRadius: "3rem",
                    backgroundColor: "white",
                  }}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
        </div>
      )}

      {activeTabDonate && (
        <div
          style={{
            marginTop: "120px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          className="donate-container"
        >
          <h1 style={{ margin: "25px" }}>Donate Details</h1>

          <Table
            striped
            bordered
            hover
            responsive
            style={{ margin: "25px", marginRight: "25px" }}
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>District</th>
                <th>DonationType</th>
                <th>Area</th>
              </tr>
            </thead>
            {currentRecords.map((item) => (
              <tbody key={item.id}>
                <tr>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>{item.mobile}</td>
                  <td>{item.dist}</td>
                  <td>{item.donateType}</td>
                  <td>{item.area}</td>
                </tr>
              </tbody>
            ))}
          </Table>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={handleChangePage}
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "25px",
            }}
          />
        </div>
      )}

      {activeTabImages && (
        <div>
          
          <Fade>
            <div
              className="p-1 p-lg-5 pb-2"
              style={{
                marginTop: 140,
                paddingTop: 3,
                borderRadius: 5,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                style={{
                  fontSize: "1.6rem",
                  color: "red",
                  fontStyle: "italic",
                  marginBottom: 10,
                }}
              >
                Gallery
              </Typography>
              <LightGallery
                onBeforeSlide={() => {}}
                speed={790}
                plugins={[lgThumbnail, lgZoom, lgShare, lgRotate]}
              >
                {galleryImages.map((data) => (
                  <a className="galler-image" href={data.image} key={data.id}>
                    <img
                      src={data.image}
                      className="mb-2"
                      style={{
                        width: "33.33333333333333%",
                        borderRadius: 2,
                        border: "1px solid lightgray",
                      }}
                    />
                  </a>
                ))}
              </LightGallery>
            </div>
          </Fade>
        </div>
      )}
    </div>
  );
}

export default AdminHome;

import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useSelector, useDispatch } from 'react-redux';
import { OpenUploadBox } from '../UserRedux/Action/StateAction';
import { FaUpload } from 'react-icons/fa';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import Swal from 'sweetalert2';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { Typography } from '@mui/material';

export default function UploadImage() {

    const [image, setImage] = React.useState(null)
    const [district, setDistrict] = React.useState()
    const [desc, setDesc] = React.useState()

    const uplodedImage = () => {
        Swal.fire({
            title: "Uploaded",
            text: "Image successfully uploded",
            confirmButtonColor: "rgb(126, 110, 228)",
            cancelButtonColor: "#d33",
            showCancelButton: false,
            confirmButtonText: "Ok"
        }).then((result) => {
            if (result.value) {
                return
            }
        })
    }

    const uploadImage = () => {

        const formData = new FormData()

        formData.append('file', image)
        formData.append('district', district)
        formData.append('desc', desc)

        axios.post('https://mealsapi.emedha.in/upload/image-upload', formData).then((res) => {
            if (res.data.status === 200) {
                uplodedImage()
                dispatch(OpenUploadBox())
            }
        })
    }

    const [Validated, setValidated] = React.useState(false);

    const uplodedImageValidation = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            uploadImage()
        }

        setValidated(true);
    };


    const myState = useSelector((state) => state.UploadImage)
    const dispatch = useDispatch()

    return (
        <div>
            <Dialog
                open={myState}
                onClose={() => dispatch(OpenUploadBox())}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CloseIcon onClick={() => {
                    dispatch(OpenUploadBox())
                }} style={{ cursor: 'pointer', position: 'absolute', top: 17, right: 17 }} />

                <DialogTitle style={{ fontSize: 18 }} id="alert-dialog-title">
                    Upload Image 
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography>Select Image {image === null ? '' : '(Image Successfully Selected)'}</Typography>
                        <Button
                            style={{ backgroundColor: 'white', borderRadius: '0.3rem', width: '100%', height: '7rem', border: '1px solid lightgray', borderStyle: 'dashed', boxShadow: 'none' }}
                            variant="contained"
                            component="label"

                        >
                            <FaUpload style={{ fontSize: 23, zIndex: 20, color: 'rgb(40, 251, 251)' }} />
                            <input
                                required
                                type="file"
                                hidden
                                onChange={(e) => setImage(e.target.files[0])}
                            />
                        </Button>
                        <Form noValidate validated={Validated} onSubmit={uplodedImageValidation} >
                            <Row className="mb-3 mt-3">

                                <Form.Group className='mb-4' as={Col} md="12" controlId="validationCustom01">

                                    <select
                                        required
                                        type="text"
                                        className='inputField form-select'

                                        aria-label="Default select example"
                                        placeholder="Message *"
                                        style={{ padding: '0.8rem', borderRadius: '0.1rem', width: '100%', border: '1px solid lightgray' }}
                                        onChange={(e) => {
                                            setDistrict(e.target.value)
                                        }}
                                    >
                                        <option selected value="" disabled >District *</option>
                                        <option>District 320 A</option>
                                        <option>District 320 B</option>
                                        <option>District 320 C</option>
                                        <option>District 320 D</option>
                                        <option>District 320 E</option>
                                        <option>District 320 F</option>
                                        <option>District 320 G</option>
                                        <option>District 320 H</option>

                                    </select>

                                    <Form.Control.Feedback type="invalid">
                                        Requirement can't be empty
                                    </Form.Control.Feedback>

                                </Form.Group>
                                <Form.Group className='mb-4' as={Col} md="12" controlId="validationCustom01">

                                    <textarea
                                        rows={3}
                                        required
                                        type="text"
                                        className='inputField'
                                        placeholder="Description*"
                                        style={{ padding: '0.8rem', borderRadius: '0.1rem', width:'100%', outline:'none', border:'1px solid lightgray' }}
                                        onChange={(e) => setDesc(e.target.value)}

                                    />

                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Valid mobile
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                        </Form>
                         
                        
                        <button onClick={() => uploadImage()} style={{ backgroundColor: 'white',width: '100%' }} className='car-button' >Upload</button>

                    </DialogContentText>
                </DialogContent>

            </Dialog>
        </div >
    );
}
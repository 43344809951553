export const OpenDonateBox = () => {

    return {
        'type': 'updateState',
    }
}

export const OpenDrawer = () => {

    return {
        'type': 'updateDrawerState',
    }
}

export const OpenUploadBox = () => {

    return {
        'type': 'updateUploadImage',
    }
}
 
import React from 'react'
import SideBar from './SideBar'
import NavigationTop from '../../Component/Navigation'
import CarouselTop from './TopCarousel'
import CarouselLaptop from './CarouselLaptop'
import Footer from '../../Component/Footer'

export const Home = () => {
    return (
        <div>
            <NavigationTop />
            <SideBar />
            <div className='d-block d-md-none' >
                <CarouselTop />
            </div>
            <div className='d-none d-md-block'>
                <CarouselLaptop />
            </div>
            <Footer />
        </div>
    )
}
// import React from 'react';
// import { Navigate, useLocation } from 'react-router-dom';

// const ProtectedRoute = ({ children }) => {
//   const adminEmail = sessionStorage.getItem('adminEmail');
//   const password = sessionStorage.getItem('password');
//   const location = useLocation();

//   if (!adminEmail || !password) {
//     return <Navigate to="/admin-login" state={{ from: location }} />;
//   }

//   return children;
// };

// export default ProtectedRoute;


import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element }) => {
  const isAuthenticated = !!sessionStorage.getItem("adminEmail");

  return isAuthenticated ? element : <Navigate to="/admin-login" />;
};

export default ProtectedRoute;

const setState = false
const setDrawerState = false
const setUploadImageState = false

export const StateUpdate = (state = setState, action)=>{

    switch (action.type) {
        case 'updateState' : return !state
        default : return state
    }

}


export const DrawerUpdate = (state = setDrawerState, action)=>{

    switch (action.type) {
        case 'updateDrawerState' : return !state
        default : return state
    }

}

export const UploadImage = (state = setUploadImageState, action)=>{

    switch (action.type) {
        case 'updateUploadImage' : return !state
        default : return state
    }

}
import Carousel from 'react-bootstrap/Carousel';
import { OpenDonateBox } from '../../UserRedux/Action/StateAction';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

function CarouselLaptop() {

    const dispatch = useDispatch()


    return (
        <Carousel controls={false}  style={{ marginTop: 10 }} >
            <Carousel.Item>
                {/* <img src={require('../../images/car1.jpeg')} style={{ width: '100%' }} /> */}
                    <video width="100%" height="auto"  muted autoPlay loop>
                        <source src={require('../../images/mealsonvi.mp4')} type="video/mp4" />
                       
                    </video>
                <Carousel.Caption>
                    <h3 style={{ textShadow: '2px 3px 3px black', fontSize: 34, fontWeight: '600' }} >Donate  Now, Celebrate  Birthday or any Special Occasion of yours or your Family & Friends </h3>
                <Link to='/donate'>    <img  src='https://i.giphy.com/media/BNDlFWRKTb4xGt5gxE/giphy.webp' width='210px' style={{ cursor: 'pointer' }} ></img></Link>
                </Carousel.Caption>
            </Carousel.Item>
            {/* <Carousel.Item>
                <img src={require('../../images/car2.jpeg')} style={{ width: '100%' }} />
                <Carousel.Caption>
                    <h3 style={{ textShadow: '2px 3px 3px black', fontSize: 34, fontWeight: '600' }} >Donate  Now, Celebrate  Birthday or any Special Occasion of yours or your Family & Friends </h3>
                    <img onClick={() => dispatch(OpenDonateBox())} src='https://i.giphy.com/media/BNDlFWRKTb4xGt5gxE/giphy.webp' width='210px' style={{ cursor: 'pointer' }} ></img>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
                <img src={require('../../images/car3.jpeg')} style={{ width: '100%' }} />
                <Carousel.Caption>
                    <h3 style={{ textShadow: '2px 3px 3px black', fontSize: 34, fontWeight: '600' }} >Donate  Now, Celebrate  Birthday or any Special Occasion of yours or your Family & Friends </h3>
                    <img onClick={() => dispatch(OpenDonateBox())} src='https://i.giphy.com/media/BNDlFWRKTb4xGt5gxE/giphy.webp' width='210px' style={{ cursor: 'pointer' }} ></img>
                </Carousel.Caption>
            </Carousel.Item> */}
        </Carousel>
    );
}

export default CarouselLaptop;